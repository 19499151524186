import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Albums from "../components/Albums";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const MusicPageTemplate = ({
  image,
  title,
  albums,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div className="content has-background-black">
      <FullWidthImage img={heroImage} title={title} />
      <Albums gridItems={albums} /> 
    </div>
  );
};

MusicPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  albums: PropTypes.array,
};

const MusicPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <MusicPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        albums={frontmatter.albums}
      />
    </Layout>
  );
};

MusicPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MusicPage;

export const musicPageQuery = graphql`
  query MusicPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        albums {
          image {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 100, layout: CONSTRAINED)
            }
          }
          title
          released
          recorded
          recordLink
          tracklist {
            title
            length
          }
          description
        }
      }
    }
  }
`;
