import * as React from "react";
import PropTypes from "prop-types";

const TracksGrid = ({ gridItems }) => (
  <span>
    {gridItems.map((item, key) => (
      <span key={key} style={{display: "block"}}>
        <code style={{ backgroundColor: "inherit", color: "inherit" }}>{key + 1}.&nbsp;{item.title}&nbsp;({item.length})</code>
      </span>
    ))}
  </span>
);

TracksGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      length: PropTypes.string,
    })
  ),
};

export default TracksGrid;
