import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Traks from "../components/Tracks";

const AlbumGrid = ({ gridItems }) => (
  <div>
    {gridItems.map((item, key) => (
      <section className="section" key={key}>
        <div className="container">
          <div className="section columns">
            <div className="column is-10 is-offset-1">
              <div className="columns">
              <div className="column is-6 has-text-centered">
                <div style={{display: "inline-block"}}>
                  <PreviewCompatibleImage imageInfo={item} imageStyle={{ borderRadius: "5px", border: ".1rem solid" }} />
                </div>
              </div>
              <div className="column is-6">
                <h2 className="has-text-primary">{item.title}</h2>
                <p><span className="bparagraph">Released:&nbsp;</span>{item.released}</p>
                <p><span className="bparagraph">Recorded:&nbsp;</span><a href={item.recordLink} target="_blank" rel="noreferrer">{item.recorded}</a></p>
                <p><span className="bparagraph">Track listing:&nbsp;</span><Traks gridItems={item.tracklist} /></p>
                <p className="jparagraph">{item.description}</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    ))}
  </div>
);

AlbumGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default AlbumGrid;
